import React from 'react';
import ReactCookieConsent from 'react-cookie-consent';
import {IAF_MAIN_URL} from 'configs/Environment';
import urljoin from 'url-join';
import {encrypt} from 'utils/encrypt';
import {COOKIE_PREFIX, DOMAIN, IS_ENV_DEV} from 'configs';
import {IconFA} from 'components/Icons';

const style = {
  background: 'rgba(255,255,255, 0.95)',
  boxShadow: '4px 2px 16px 0px rgba(179,172,179,1)',
  color: 'black',
  left: '0',
  alignItems: 'center',
  padding: '2rem 1rem',
};

const buttonStyle = {
  background: '#1428A0',
  color: 'white',
  borderRadius: '20px',
  height: '40px',
  width: '200px',
};

const declineButtonStyle = {
  background: 'transparent',
  color: 'black',
  position: 'absolute',
  top: '0',
  right: '0',
  padding: '0',
};
const domain = IS_ENV_DEV ? '' : DOMAIN;
const cookieName = `${COOKIE_PREFIX}_consent`;

function CookieConsent(props) {
  const {visible, onAccept, onDecline} = props;

  const cookieValue = encrypt(true);
  const declineCookieValue = encrypt(false);

  return (
    <ReactCookieConsent
      // debug={true
      visible={visible ? 'show' : 'hidden'}
      enableDeclineButton
      onAccept={() => onAccept()}
      onDecline={() => onDecline()}
      location="bottom"
      cookieName={cookieName}
      cookieValue={cookieValue}
      declineCookieValue={declineCookieValue}
      containerClasses="cookie-container"
      style={style}
      flipButtons={true}
      buttonText="Accept"
      buttonId="consent-accept-button"
      declineButtonId="consent-decline-button"
      buttonStyle={buttonStyle}
      declineButtonText={<IconFA name="close" id="consent-decline-icon" />}
      declineButtonStyle={declineButtonStyle}
      extraCookieOptions={{domain: domain}}
    >
      <p className="mb-0">
        IAF CertSearch uses cookies. To find out more, see the{' '}
        <a href={urljoin(IAF_MAIN_URL, '/policies/privacy')}>
          Privacy Policies
        </a>
      </p>
    </ReactCookieConsent>
  );
}

export {CookieConsent};
