import styles from './styles.module.scss';

import React, {useState, useContext, useEffect} from 'react';
import {withRouter} from 'react-router';
import {debounce} from 'lodash/function';
import Cookies from 'utils/cookies';
import {alertByError} from 'utils/alerts';
import {Col, Row} from 'reactstrap';

import {getSearchSuggestCompany, getDashboardSearchLimit} from 'api/search';
import {AuthContext} from 'contexts/AuthContext';
import {SearchAsyncBase} from 'components/Forms/FormBases';
import {IAF_MAIN_URL} from 'configs/Environment';
import urljoin from 'url-join';
import {removePrefix} from 'utils/string';
import {MDB_PREFIX} from 'configs';

const defaultProps = {
  name: 'company_id',
  isClearable: true,
  defaultOptions: false,
  verifyCaptcha: () => {},
};

function SearchSelectCE(props) {
  const {isAuth} = useContext(AuthContext);
  const {
    history,
    verifyCaptcha,
    name,
    selected,
    value,
    onInputChange,
    onSelect,
    ...rest
  } = props;
  const [keyword, setKeyword] = useState('');
  const [data, setData] = useState(true);

  useEffect(() => {
    isAuth && getLimit();
  }, [true]);

  async function getLimit() {
    const {data} = await getDashboardSearchLimit();
    setData(data);
  }

  async function loadOptions(value, callback) {
    setKeyword(value);

    let result = [];

    try {
      const {data} = await getSearchSuggestCompany({query: value});

      result = data.map((item) => {
        let obj = {};
        for (const [key, value] of Object.entries(item)) {
          obj = {company_id: key, company_name: value};
        }
        return obj;
      });
    } catch (e) {
      if (e.status === 429) {
        Cookies.captchaToken = '';
        await alertByError(e);
      } else {
        if (e.code === 'invalid_captcha_token') verifyCaptcha();
      }
    }

    if (onInputChange) onInputChange(value, result);
    return callback(result);
  }

  function handleChange(selected) {
    isAuth && getLimit();
    if (selected) {
      const {company_id} = selected;
      const mdbCeId = removePrefix(company_id, MDB_PREFIX.ce);

      /*if (isAuth) {
        addSearchAccountHistory({
          mdbce_id: company_id,
          ce_name: company_name,
          keyword: keyword,
          type: 'company',
        });
      }*/

      window.location.replace(
        urljoin(IAF_MAIN_URL, `/certified-entity/${mdbCeId}`)
      );
      if (onSelect) onSelect(selected, keyword);
    }
  }

  const noOptionsMessage = ({inputValue}) =>
    inputValue ? (
      <div className={styles['search__not-found']}>
        <div className="mb-2">Can’t find the company, you’re looking for?</div>
        <div>
          <a href={urljoin(IAF_MAIN_URL, '/contact')}>
            Information request form
          </a>
        </div>
      </div>
    ) : (
      <div className={styles['search__not-found']}>
        {isAuth ? (
          <Row>
            <Col lg={1}>
              <i
                className="fa fa-exclamation-circle fa-4x"
                style={{color: 'orange'}}
              />
            </Col>
            <Col>
              <div>
                <b>
                  {data.try} / {data.max} search limit used.
                </b>
                <br />
                Want to increase your search limit? Contact us at,
                <br />
                <a href="mailto: support@iafcertsearch.org">
                  <b>IAF CertSearch support.</b>
                </a>
              </div>
            </Col>
          </Row>
        ) : (
          'Provide company name to search...'
        )}
      </div>
    );

  const selectOptions = {
    loadOptions: debounce(loadOptions, 200),
    onChange: handleChange,
    getOptionLabel: (option) => option.company_name,
    getOptionValue: (option) => option.company_id,
    placeholder: 'Search certification by company name',
    noOptionsMessage: noOptionsMessage,
    ...rest,
  };

  return <SearchAsyncBase {...selectOptions} />;
}

SearchSelectCE.defaultProps = defaultProps;

export default withRouter(SearchSelectCE);
