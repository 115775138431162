import React from 'react';

import {Nav} from 'reactstrap';

import PrivateNavUser from './PrivateNavUser';
import PrivateNavSearch from './PrivateNavSearch';
import PrivateNavNotification from './PrivateNavNotification';

function PrivateNav(props) {
  const {dark} = props;

  return (
    <>
      <div className="align-items-lg-center d-none d-lg-block">
        <PrivateNavSearch />
      </div>

      <Nav className="align-items-lg-center ml-auto flex-row" navbar>
        <PrivateNavNotification dark={dark} />

        <PrivateNavUser />
      </Nav>
    </>
  );
}

export default PrivateNav;
