import styles from './styles.module.scss';

import React, {useState, useContext, useEffect} from 'react';

import {debounce} from 'lodash/function';
import keys from 'lodash/keys';
import drop from 'lodash/drop';
import first from 'lodash/first';
import {Col, Row} from 'reactstrap';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';

import {getSearchSuggestCertificate, getDashboardSearchLimit} from 'api/search';
import {AuthContext} from 'contexts/AuthContext';
import {SearchAsyncBase} from 'components/Forms/FormBases';
import {MDB_PREFIX} from 'configs';
import {alertByError} from 'utils/alerts';
import {removePrefix} from 'utils/string';
import Cookies from 'utils/cookies';

import urlJoin from 'url-join';
import {IAF_MAIN_URL} from 'configs/Environment';

const defaultProps = {
  name: 'certification',
  isClearable: true,
  defaultOptions: false,
  verifyCaptcha: () => {},
};

function SearchSelectCE(props) {
  const {isAuth} = useContext(AuthContext);
  const {
    history,
    verifyCaptcha,
    name,
    selected,
    value,
    onInputChange,
    onSelect,
    ...rest
  } = props;
  const [keyword, setKeyword] = useState('');
  const [data, setData] = useState(true);

  useEffect(() => {
    isAuth && getLimit();
  }, [true]);

  async function getLimit() {
    const {data} = await getDashboardSearchLimit();
    await setData(data);
  }

  async function loadOptions(value, callback) {
    setKeyword(value);

    let result = [];

    if (value) {
      try {
        const {data} = await getSearchSuggestCertificate({query: value});

        if (Array.isArray(data)) {
          result = data.map((item) => {
            let obj = {};
            for (const [key, value] of Object.entries(item)) {
              obj = {
                company_certifications_id: key,
                cert_number: value,
              };
            }
            return obj;
          });
        } else {
          result = keys(data).map((item) => {
            const cert_number = data[item].length > 0 ? first(data[item]) : '';
            const ces = data[item].length > 1 ? drop(data[item], 1) : [];

            return {
              company_certifications_id: item,
              cert_number: cert_number,
              ces: ces,
            };
          });
        }
      } catch (e) {
        if (e.status === 429) {
          Cookies.captchaToken = '';
          await alertByError(e);
        } else {
          if (e.code === 'invalid_captcha_token') verifyCaptcha();
        }
      }
    }

    if (onInputChange) onInputChange(value, result);
    return callback(result);
  }

  function handleChange(selected) {
    isAuth && getLimit();
    if (selected) {
      const {company_certifications_id} = selected;
      const mdbCertId = removePrefix(
        company_certifications_id,
        MDB_PREFIX.cert
      );

      window.location.replace(
        urlJoin(IAF_MAIN_URL, '/certification', mdbCertId)
      );
      if (onSelect) onSelect(selected, keyword);
    }
  }

  const noOptionsMessage = ({inputValue}) =>
    inputValue ? (
      <div className={styles['search__not-found']}>
        <div className="mb-2">
          Can’t find the certification, you’re looking for?
        </div>
        <div>
          <Link to="/contact">Information request form</Link>
        </div>
      </div>
    ) : (
      <div className={styles['search__not-found']}>
        {isAuth ? (
          <Row>
            <Col lg={1}>
              <i
                className="fa fa-exclamation-circle fa-4x"
                style={{color: 'orange'}}
              />
            </Col>
            <Col>
              <div>
                <b>
                  {data.try} / {data.max} search limit used.
                </b>
                <br />
                Want to increase your search limit? Contact us at,
                <br />
                <a href="mailto: support@iafcertsearch.org">
                  <b>IAF CertSearch support.</b>
                </a>
              </div>
            </Col>
          </Row>
        ) : (
          'Search companies by certificate number ...'
        )}
      </div>
    );

  const selectOptions = {
    loadOptions: debounce(loadOptions, 200),
    onChange: handleChange,
    getOptionLabel: (option) => option.cert_number,
    getOptionValue: (option) => option.company_certifications_id,
    placeholder: 'Search certification by certificate number',
    noOptionsMessage: noOptionsMessage,
    ...rest,
  };

  return <SearchAsyncBase {...selectOptions} />;
}

SearchSelectCE.defaultProps = defaultProps;

export default withRouter(SearchSelectCE);
