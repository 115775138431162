import styles from './styles.module.scss';
import React from 'react';
import {withRouter} from 'react-router';
import {withAuth} from 'contexts/AuthContext';
import DataLayerPush from 'utils/gtm';

import {
  InputGroup,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  ButtonDropdown,
} from 'reactstrap';
import {SearchSelectCE, SearchSelectCert} from 'components/Forms/Searches';
import {CaptchaModal} from 'components/Captcha';
import {components} from 'react-select';
import classNames from 'classnames';
import {first, isEmpty, slice} from 'lodash';

const CustomOption = (props) => {
  const {data, ...rest} = props;
  const {cert_number, ces = []} = data || {};

  if (!ces) {
    return <components.Option {...rest}>{cert_number}</components.Option>;
  }

  const firstCE = first(ces) || '';
  const restCEs = slice(ces, 1);

  return (
    <components.Option {...rest}>
      <p
        className={classNames('text-dark font-weight-bold p-0', ces && 'mb-1')}
        style={{fontSize: '12px'}}
      >
        {cert_number}
      </p>

      {ces && (
        <div>
          {firstCE && (
            <p
              class="text-secondary p-0 m-0"
              style={{fontSize: '12px'}}
              title={firstCE}
            >
              <span style={{overflowWrap: 'break-word'}}>{firstCE}</span>
              {restCEs.length > 0 && (
                <span
                  style={{color: 'var(--gray)', filter: 'opacity(0.5)'}}
                  className="ml-1"
                >
                  (+{restCEs.length} more)
                </span>
              )}
            </p>
          )}
        </div>
      )}
    </components.Option>
  );
};

class PrivateNavSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,

      searchType: 'certified-entity',
      label: 'Company Name',
    };

    this.toggle = this.toggle.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  onSelectChange(event) {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
      searchType: event.target.value,
      label: event.target.innerText,
      active: 'certified-entity',
    });
  }

  render() {
    const {label, dropdownOpen, searchType} = this.state;
    const {eventSearchInput, eventSearchClick} = DataLayerPush;

    return (
      <CaptchaModal initialCheck={false}>
        {({verifyCaptcha}) => {
          return (
            <div className={styles['search__forms']}>
              <InputGroup>
                <ButtonDropdown isOpen={dropdownOpen} toggle={this.toggle}>
                  <DropdownToggle
                    className={styles['form-control__select']}
                    caret
                  >
                    {label}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      value="certified-entity"
                      onClick={(event) =>
                        this.onSelectChange(event, 'certified-entity')
                      }
                    >
                      Company Name
                    </DropdownItem>
                    <DropdownItem
                      value="certification"
                      onClick={(event) =>
                        this.onSelectChange(event, 'certification')
                      }
                    >
                      Certification ID
                    </DropdownItem>
                  </DropdownMenu>
                </ButtonDropdown>

                <div onClick={verifyCaptcha}>
                  {searchType === 'certified-entity' ? (
                    <SearchSelectCE
                      className={styles['form-control-search']}
                      onInputChange={(input, result) => {
                        eventSearchInput({
                          searchInputEntityType: 'ce',
                          searchInputLocation: 'header',
                          searchInputKeyword: input,
                          searchInputResult: !isEmpty(result),
                        });
                      }}
                      onSelect={(selected, keyword) => {
                        const {company_id, company_name} = selected || {};

                        if (!isEmpty(selected)) {
                          eventSearchClick({
                            searchClickEntityType: 'ce',
                            searchClickLocation: 'header',
                            searchClickKeyword: keyword,
                            searchClickEntityName: company_name,
                            searchClickEntityId: company_id,
                          });
                        }
                      }}
                    />
                  ) : (
                    <SearchSelectCert
                      className={styles['form-control-search']}
                      onInputChange={(input, result) => {
                        eventSearchInput({
                          searchInputEntityType: 'cert',
                          searchInputLocation: 'header',
                          searchInputKeyword: input,
                          searchInputResult: !isEmpty(result),
                        });
                      }}
                      onSelect={(selected, keyword) => {
                        const {company_certifications_id, company_name} =
                          selected || {};

                        if (!isEmpty(selected)) {
                          eventSearchClick({
                            searchClickEntityType: 'ce',
                            searchClickLocation: 'header',
                            searchClickKeyword: keyword,
                            searchClickEntityName: company_name,
                            searchClickEntityId: company_certifications_id,
                          });
                        }
                      }}
                      components={{
                        Option: CustomOption,
                      }}
                    />
                  )}
                </div>
              </InputGroup>
            </div>
          );
        }}
      </CaptchaModal>
    );
  }
}

export default withRouter(withAuth(PrivateNavSearch));
