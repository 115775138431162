import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {keys} from 'lodash/object';

import {withPage} from 'contexts/PageContext';
import {SIZES} from 'configs';
import IMPrivateSidebar from 'components/Layouts/IMPrivateSidebar';

const propTypes = {
  header: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  sidebar: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      left: PropTypes.shape({
        showAt: PropTypes.oneOf(keys(SIZES)),
        component: PropTypes.element.isRequired,
      }),
      right: PropTypes.shape({
        showAt: PropTypes.oneOf(keys(SIZES)),
        component: PropTypes.element.isRequired,
      }),
    }),
  ]),

  footer: PropTypes.bool,
};

const defaultProps = {
  header: false,
  footer: false,
  sidebar: false,
  isLoading: false,
};

function Layout(props) {
  const {page, header, footer, sidebar, children} = props;

  useEffect(() => {
    page.setLayout({header, footer, sidebar});
  }, [sidebar]);

  return children;
}

Layout.propTypes = propTypes;
Layout.defaultProps = defaultProps;

export const BaseLayout = withPage(Layout);

export const PrivateLayout = ({children}) => (
  <BaseLayout
    header
    footer
    sidebar={{
      left: {
        showAt: SIZES.lg,
        component: <IMPrivateSidebar />,
        width: '240px',
      },
    }}
  >
    {children}
  </BaseLayout>
);

export const PublicLayout = ({header = true, footer = true, children}) => (
  <BaseLayout header={header} footer={footer}>
    {children}
  </BaseLayout>
);

export default BaseLayout;
