import React, {useState, useEffect} from 'react';
import cx from 'classnames';
import AsyncSelect from 'react-select/async';
import {IconFA} from 'components/Icons';
import {isEmpty} from 'lodash';

const disabledStyles = {
  control: (styles) => ({
    ...styles,
    color: '#6c757d',
    borderRadius: 0,
    borderColor: '#ced4da',
    backgroundColor: '#e9ecef',
  }),
  option: (styles) => ({...styles, color: '#000', borderRadius: 0}),
  input: (styles) => styles,
  placeholder: (styles) => styles,
  singleValue: (styles) => styles,
};

const enabledStyles = {
  control: (styles) => ({
    ...styles,
    borderRadius: 0,
    borderColor: '#ced4da',
    backgroundColor: 'white',
  }),
  option: (styles) => ({...styles, borderRadius: 0}),
  input: (styles) => styles,
  placeholder: (styles) => styles,
  singleValue: (styles) => styles,
};

const defaultProps = {
  components: {
    DropdownIndicator: () => {
      return <IconFA name="search" className="mr-2 text-secondary" />;
    },
    IndicatorSeparator: () => null,
  },
  className: 'react-select-container',
  classNamePrefix: 'react-select',
};

function SearchAsyncBase(props) {
  const {
    isDisabled = false,
    onChange,
    className,
    required,
    value,
    defaultValue,
    ...rest
  } = props;

  const [selectedValue, setSelectedValue] = useState(value);

  useEffect(() => {
    if (!isEmpty(value)) setSelectedValue(value || defaultValue);
  }, [value]);

  const _className = cx(className, {
    'react-select--valid':
      required && !isDisabled ? !isEmpty(selectedValue) : !isDisabled,
    'react-select--invalid':
      required && !isDisabled ? isEmpty(selectedValue) : false,
  });

  function handleChange(selected) {
    setSelectedValue(selected);
    if (onChange) onChange(selected);
  }

  return (
    <AsyncSelect
      value={selectedValue}
      className={_className}
      onChange={handleChange}
      required={required}
      isDisabled={isDisabled}
      styles={isDisabled ? disabledStyles : enabledStyles}
      {...rest}
    />
  );
}

SearchAsyncBase.defaultProps = defaultProps;

export default SearchAsyncBase;
